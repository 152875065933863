.container {
    min-height: calc(100vh - 57px);
    /*background-image: url("../../assets/images/background.png");*/
}

.root {
    min-height: calc(100vh - 125px);
}

.leftPanel {
    display: flex;
    border-left: 1px solid #FEFEFE;
    border-right: 1px solid #FEFEFE;
    position: relative;
}

.sticker {
    position: absolute;
    width: 100px;
    height: 100px;
    transition: all 3s;
    right: -50px;
    top: 35px
}

.orderContainer {
    flex-grow: 1;
    padding: 30px;
    padding-top: 60px;
}

.itemWrapper {
    padding: 5px;
    margin-top: 50px;
}

.checkbox {
    color: white;
    padding: 0;
}

.orderContainer .itemCaption {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    font-size: 54px;
    line-height: 54px;
    margin-bottom: 40px;
    text-transform: uppercase;
}

.orderContainer .itemIcon {
    margin-top: -5px;
    width: 50px;
    height: 60px;
}

.orderContainer .itemTitle {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.orderContainer .itemSubtitle {
    color: #FEFEFE;
    font-family: Inter, sans-serif;
}

.orderLabel {
    padding: 10px 40px;
    border-top: 1px solid #FEFEFE;
    border-bottom: 1px solid #FEFEFE;
}

.orderLabel .label {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
}

.orderDetail {
    padding: 40px
}

.itemLabel {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    text-transform: uppercase;
}

.itemSubLabel {
    color: #706c6c;
    font-family: 'Sunset', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
}

.itemValue {
    color: #FEFEFE;
    font-family: Inter, sans-serif;
    margin-top: 10px;
}

.submitButton {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #FEFEFE;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    transition: all 1s;
    box-shadow: 4px 4px 2px 0 rgb(249 66 103)
}

.submitButton:after {
    position: absolute;
    content: '';
    top: -1px;
    left: -1px;
    border-top: 20px solid black;
    border-right: 20px solid transparent;
    z-index: 99;
}

.submitButtonCaption {
    font-family: 'Sunset', sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    margin: 8px 0;
    padding-left: 15px;
}

.submitButton:hover {
    box-shadow: none;
    background: linear-gradient(0deg, #F94139, #F944A8);
}

.submitButton:hover .submitButtonCaption {
    color: #FEFEFE;
}

.submitButton:hover img {
    filter: brightness(0%) invert(100%);
}

.content {
    background-image: url("../../assets/images/background.png");
    background-color: #000000;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-right: 1px solid #FEFEFE;
}

.hodler {
    /*animation: move 5s linear infinite;*/
    width: 400px;
}

.messageBox {
    border: 1px solid #FEFEFE;
    padding: 16px 0;
    height: 32px;
    overflow: hidden;
}

.marquee {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 1rem;
    position: relative;
}

.marqueeContent {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 100%;
    gap: 1rem;
    animation: scroll 30s linear infinite;
}

.marqueeItem {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.marqueeItem span {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    font-size: 32px;
}


@keyframes scroll {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 1rem));
    }
}

@keyframes move {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
    100% {
        transform: translateY(0);
    }
}

@media screen and (max-width: 900px) {
    .sticker {
        width: 60px;
        height: 60px;
        top: -30px;
        left: 50px;
    }

    .orderContainer {
        padding-top: 55px;
        border-top: 1px solid #FEFEFE;
    }

    .content {
        padding: 30px;
        min-height: calc(50vh);
    }

    .hodler {
        width: 250px;
    }
}


@media only screen and (min-width: 1601px) {
    /* styles for browsers larger than 1440px; */
    .hodler {
        width: 700px;
    }
}

@media only screen and (min-width: 2000px) {
    /* styles for browsers larger than 1440px; */
    .hodler {
        width: 50%;
    }
}
.modalStyle {
    position: absolute;
    border: 1px solid #FEFEFE;
    background: url("../../assets/images/modal_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 36px;
    padding-bottom: 36px;
}

.bgEllipse {
    position: absolute;
    top: 0%;
    left: 0%;
}

.modalStyle .itemCaption {
    color: #FEFEFE;
    font-family: 'Sunset', sans-serif;
    font-size: 40px;
    line-height: 40px;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.closeIcon {
    cursor: pointer;
}

.inputLabel {
    margin-bottom: 5px;
}

.inputField {
    background-color: #0D0D0D;
    border: 1px solid #FEFEFE;
    width: 95%;
    color: inherit;
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px;
}

.submitButton {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #FEFEFE;
    border: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    text-decoration: none;
    transition: all 1s;
    box-shadow: 4px 4px 2px 0 rgb(249 66 103)
}

.submitButton:after {
    position: absolute;
    content: '';
    top: -1px;
    left: -1px;
    border-top: 20px solid black;
    border-right: 20px solid transparent;
    z-index: 99;
}

.submitButtonCaption {
    font-family: 'Sunset', sans-serif;
    text-transform: uppercase;
    font-size: 24px;
    margin: 8px 0;
    padding-left: 15px;
}

.submitButton:hover {
    box-shadow: none;
    background: linear-gradient(0deg, #F94139, #F944A8);
}

.submitButton:hover .submitButtonCaption {
    color: #FEFEFE;
}

.submitButton:hover img {
    filter: brightness(0%) invert(100%);
}

@media screen and (max-width: 900px) {
    .modalStyle {
        width: 70%;
    }
}